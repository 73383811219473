"use client";

import { cn } from "@/lib/utils";
import React from "react";
import Marquee from "react-fast-marquee";

const items = [
  "ihg",
  "hilton",
  "marriott",
  "hyatt",
  "united",
  "chase",
  "amex",
  "bilt",
  "capital one",
  "american",
  "delta",
  "alaska",
  "wyndham",
  "accor",
  "jetblue",
  "southwest",
];

type Props = {
  className?: string;
};

const BannerSlider: React.FC<Props> = ({ className }) => {
  return (
    <Marquee className={cn("w-full", className)} direction="right">
      {items.map((item, index) => (
        <div className="flex items-center justify-center" key={index}>
          <div className="whitespace-nowrap text-xs font-semibold uppercase tracking-[10px] text-primary/80 lg:text-xl">
            {item}
          </div>
          <div className="ml-4 mr-6 h-1 w-1 rounded-full bg-white/80" />
        </div>
      ))}
    </Marquee>
  );
};

export default BannerSlider;
