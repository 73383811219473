import GondolaLogo from "@/components/ui/svgComponents/GondolaLogo";
import React, { PropsWithChildren } from "react";

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative flex w-full flex-col items-center justify-start gap-8">
      <div className="flex h-8 w-full items-center justify-start gap-5 lg:h-[53.32px] lg:gap-[124px]">
        <div className="h-[0px] shrink grow basis-0 border border-primary-gold/50"></div>
        <GondolaLogo className="h-4 w-4 lg:h-[52px] lg:w-[52px]" />
        <div className="h-[0px] shrink grow basis-0 border border-primary-gold/50"></div>
      </div>
      <div className="flex flex-col items-center justify-start gap-12 px-4">
        {children}
      </div>
    </div>
  );
};

export default Title;
