"use client";

import React, { useState } from "react";
import Title from "./Title";
import { cn, ppeikoFont } from "@/lib/utils";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";
import WaitlistFormModal from "@/components/modal/waitlistFormModal";
import Link from "next/link";

const tabOptions = [
  {
    id: "1",
    tabHeader: "Link Your Account",
    image: "/assets/landing-page/modern-traveller/1.png",
    subtitle: (
      <span>
        Your data is protected with bank grade encryption.{" "}
        <Link
          href="/privacy-and-security"
          className="cursor-pointer text-primary-gold"
        >
          Learn More
        </Link>
      </span>
    ),
  },
  {
    id: "2",
    tabHeader: "See All Your Points",
    image: "/assets/landing-page/modern-traveller/2.png",
    subtitle: "Easily manage your hotel, airline, and credit card points",
  },
  {
    id: "3",
    tabHeader: "Book The Best Deals",
    image: "/assets/landing-page/modern-traveller/3.png",
    subtitle: "Find the best member rates while earning points and 3% back",
  },
];

const ModernTraveller: React.FC = () => {
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = useState(false);
  const [showWaitlistFormModal, setShowWaitlistFormModal] = useState(false);

  return (
    <div className="relative mx-5 mt-16 flex flex-col items-center justify-start md:mt-32 lg:mx-28">
      <GoogleAuthModal
        headerText="Create an account"
        open={googleAuthModalOpen}
        onOpenChange={setGoogleAuthModalOpen}
      />
      <WaitlistFormModal
        open={showWaitlistFormModal}
        onOpenChange={(val) => {
          if (!val) setShowWaitlistFormModal(false);
        }}
      />
      <Title>
        <span className="text-center text-4xl font-normal capitalize leading-10 text-primary lg:text-[58px] lg:leading-[63.80px]">
          <span>Made for </span>
          <br className="md:hidden" />
          <span
            className={cn(
              "font-medium italic text-primary-gold",
              ppeikoFont.className
            )}
          >
            modern travelers
          </span>
        </span>
      </Title>
      <div className="my-4 text-center text-xl font-normal leading-relaxed text-[#b1b1b1] md:text-2xl">
        AI powered travel designed for you
      </div>
      <div className="mt-6 flex w-full flex-col items-center justify-center gap-6 lg:flex-row">
        {tabOptions.map((tab) => (
          <div
            className="flex h-[480px] w-full max-w-96 flex-col items-center justify-center gap-4 rounded-2xl border border-white/10 bg-card-background-dark-2 px-3 py-6"
            key={tab.id}
          >
            <div className="flex max-h-8 min-h-8 min-w-8 max-w-8 items-center justify-center rounded-full bg-primary-gold font-semibold text-dark">
              {tab.id}
            </div>
            <img
              src={tab.image}
              alt={tab.image}
              className="h-[230px] w-[400px] object-contain"
            />
            <div className="mb-2 flex flex-col items-center justify-center gap-2">
              <div className="text-center text-2xl font-normal leading-[28.80px] text-primary">
                {tab.tabHeader}
              </div>
              <div className="text-center text-xl font-normal text-gondola-blue-2/80">
                {tab.subtitle}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModernTraveller;
