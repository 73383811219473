"use client";

import React, { useEffect, useState } from "react";
import Title from "./Title";
import { cn, ppeikoFont } from "@/lib/utils";
import { useUI } from "@/app/context/UIProvider";

const tabOptions = [
  {
    id: "1",
    tabHeader: "Personalized Search",
    image: "/assets/landing-page/stay-sections/1.png",
    title: () => (
      <span className="text-center text-3xl leading-relaxed text-primary lg:text-start lg:text-[58px] lg:leading-[63.80px]">
        <span className="font-normal">A hotel search that</span>
        <span className={cn("italic", ppeikoFont.className)}> knows </span>
        <span className="font-normal"> you</span>
      </span>
    ),
    subtitle:
      "Get personalized hotel recommendations based on your travel history so you can easily discover your next favorite stay.",
  },
  {
    id: "2",
    tabHeader: "Points & Cash",
    image: "/assets/landing-page/stay-sections/2.png",
    title: () => (
      <span className="text-center text-3xl leading-relaxed text-primary lg:text-start lg:text-[58px] lg:leading-[63.80px]">
        <span className="font-normal">Compare points and cash rates</span>
        <span className={cn("italic", ppeikoFont.className)}> all at once</span>
      </span>
    ),
    subtitle:
      "See every hotel rate in cash and points. Gondola ranks them with a deal score based on price history, so you always know if you're getting the best value.",
  },
  {
    id: "3",
    tabHeader: "Extra Rewards",
    image: "/assets/landing-page/stay-sections/3.png",
    title: () => (
      <span className="text-center text-3xl leading-relaxed text-primary lg:text-start lg:text-[58px] lg:leading-[63.80px]">
        <span className="font-normal">Maximize your points and earn </span>
        <span className={cn("italic", ppeikoFont.className)}>3% back</span>
      </span>
    ),
    subtitle:
      "Earn the most possible credit card and hotel loyalty points. Get all the perks of booking directly with hotels plus an extra 3% cash back on every stay.",
  },
  {
    id: "4",
    tabHeader: "Room Rate Monitor",
    image: "/assets/landing-page/stay-sections/4.png",
    title: () => (
      <span className="text-center text-3xl leading-relaxed text-primary lg:text-start lg:text-[58px] lg:leading-[63.80px]">
        <span className="font-normal">Automatically save when </span>
        <span className={cn("italic", ppeikoFont.className)}>prices drop</span>
      </span>
    ),
    subtitle:
      "Gondola tracks your reservation and  rebooks if the price drops, so you always get the lowest rate without the hassle.",
  },
];

const StaySections: React.FC = () => {
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);
  const [autoRotate, setAutoRotate] = useState(false);
  const { isLessThanLg } = useUI();
  const tabSectionRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setAutoRotate(true);
        } else {
          setAutoRotate(false);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!autoRotate) return;

    const interval = setInterval(() => {
      setSelectedTab((currentTab) => {
        const index = tabOptions.findIndex(
          (tab) => tab.tabHeader === currentTab.tabHeader
        );
        const newIndex = (index + 1) % tabOptions.length;
        tabSectionRef.current?.scrollTo({
          left: newIndex * 135,
          behavior: "smooth",
        });
        return tabOptions[newIndex];
      });
    }, 7000);

    return () => clearInterval(interval);
  }, [autoRotate]);

  return (
    <div
      ref={sectionRef}
      className="relative mx-5 mt-16 flex flex-col items-center justify-start md:mt-32 lg:mx-28"
    >
      <Title>
        <span className="text-center text-4xl font-normal capitalize leading-10 text-primary lg:text-[58px] lg:leading-[63.80px]">
          <span>Smarter bookings for </span>
          <span
            className={cn(
              "font-medium italic text-primary-gold",
              ppeikoFont.className
            )}
          >
            Every Stay
          </span>
        </span>
      </Title>
      <div className="mt-6 flex w-full flex-col gap-6 lg:mt-10 lg:gap-10">
        <div
          ref={tabSectionRef}
          className="no-scrollbar flex w-full overflow-x-auto lg:justify-center lg:overflow-x-hidden"
        >
          {tabOptions.map((tab, index) => (
            <div
              id={tab.id}
              key={index}
              onClick={() => {
                setSelectedTab(tab);
              }}
              className={cn(
                "flex h-14 cursor-pointer items-center justify-center whitespace-nowrap px-6 font-normal leading-9 lg:h-20 lg:text-2xl",
                selectedTab === tab
                  ? "border-b border-primary-gold/50 text-primary-gold"
                  : "text-gondola-blue-3"
              )}
            >
              {tab.tabHeader}
            </div>
          ))}
        </div>
        <div className="flex w-full flex-col gap-10 lg:flex-row">
          <div className="flex flex-col items-center justify-center gap-5 text-lg lg:w-[55%] lg:items-start lg:gap-12">
            {selectedTab.title()}
            <div className="text-center text-gondola-blue-2 lg:text-start lg:text-2xl">
              {selectedTab.subtitle}
            </div>
          </div>
          <div className="flex justify-center lg:w-[45%] lg:justify-end">
            <img
              src={selectedTab.image}
              alt={selectedTab.image}
              className={cn(
                isLessThanLg
                  ? "w-full max-w-[450px] object-contain"
                  : "max-h-[590px] min-h-[590px] min-w-[550px] max-w-[550px] object-contain"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaySections;
