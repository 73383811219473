"use client";

import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { GoogleAuthModal } from "@/components/modal/googleAuthModal";
import WaitlistFormModal from "@/components/modal/waitlistFormModal";
import { Button } from "@/components/ui/button";
import { cn, lgBreakPoint, ppeikoFont } from "@/lib/utils";
import { ArrowRight } from "lucide-react";
import { motion } from "motion/react";
import React, { ReactNode, useEffect } from "react";
import Title from "./Title";

const INITIAL_LOADING_TIME = 1000;
const REVEAL_ANIMATION_DURATION = 1000;

const LoyaltyDashboardCard: React.FC = () => {
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [showConnectNow, setShowConnectNow] = React.useState(false);
  const [googleAuthModalOpen, setGoogleAuthModalOpen] = React.useState(false);
  const [showWaitlistFormModal, setShowWaitlistFormModal] =
    React.useState(false);

  const { isWebView } = useMainLayout();

  const onClick = () => {
    if (isWebView) {
      setShowWaitlistFormModal(true);
    } else {
      setGoogleAuthModalOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isLessThanLg = window.innerWidth < lgBreakPoint;
      if (isLessThanLg) {
        setShowConnectNow(window.scrollY > 450);
      } else {
        setShowConnectNow(window.scrollY > 550);
      }
    });
  }, []);

  useEffect(() => {
    if (!showConnectNow) {
      return;
    }
    const timeout = setTimeout(() => {
      setInitialLoading(false);
    }, INITIAL_LOADING_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, [showConnectNow]);

  return (
    <>
      <div className="relative mx-5 mt-16 flex flex-col items-center justify-start md:mt-32 lg:mx-28">
        <Title>
          <span className="-mx-2 text-center text-4xl font-normal capitalize leading-10 text-primary lg:text-[58px] lg:leading-[63.80px]">
            <span>See how much your </span>
            <span
              className={cn(
                "font-medium italic text-primary-gold",
                ppeikoFont.className
              )}
            >
              points are worth
            </span>
          </span>
        </Title>
        <div className="relative mt-10 flex w-full items-center justify-center">
          <GoogleAuthModal
            headerText="Create an account"
            open={googleAuthModalOpen}
            onOpenChange={setGoogleAuthModalOpen}
          />
          <WaitlistFormModal
            open={showWaitlistFormModal}
            onOpenChange={(val) => {
              if (!val) setShowWaitlistFormModal(false);
            }}
          />
          <img
            src="/assets/landing-page/loyalty-dashboard/1.png"
            alt="1"
            className="absolute -left-10 top-0 hidden h-[245px] w-[245px] object-cover lg:inline"
          />
          <img
            src="/assets/landing-page/loyalty-dashboard/2.png"
            alt="2"
            className="absolute -right-10 top-0 hidden h-[245px] w-[245px] object-cover lg:inline"
          />
          <img
            src="/assets/landing-page/loyalty-dashboard/3.png"
            alt="3"
            className="absolute bottom-[20%] left-28 hidden h-[245px] w-[245px] -translate-y-[20%] object-cover lg:inline"
          />
          <img
            src="/assets/landing-page/loyalty-dashboard/4.png"
            alt="4"
            className="absolute bottom-[28%] right-28 hidden h-[245px] w-[245px] -translate-y-[28%] object-cover lg:inline"
          />
          <img
            src="/assets/landing-page/loyalty-dashboard/5.png"
            alt="5"
            className="absolute -right-10 bottom-0 hidden h-[245px] w-[245px] object-cover lg:inline"
          />
          <div className="relative z-0 flex w-full items-center justify-center">
            <div className="z-10 flex min-h-[550px] w-[350px] flex-col items-center justify-center gap-5 overflow-hidden rounded-[17.23px] bg-[#162232] px-2 py-5 shadow-[0px_0px_8.616310119628906px_8.616310119628906px_rgba(30,44,61,1.00)] lg:h-[650px] lg:w-[500px] lg:gap-[41.36px] lg:px-5 lg:py-10">
              {initialLoading ? (
                <div className="flex flex-col items-center justify-center gap-5">
                  <div className="text-[32px] font-normal italic leading-[48px] text-primary-gold">
                    Magic Happening
                  </div>
                  <div className="h-2 w-48 rounded-full bg-gray-700">
                    <div
                      className="h-full rounded-full bg-primary-gold"
                      style={{
                        animation: showConnectNow
                          ? `progressAnimation ${INITIAL_LOADING_TIME / 1000}s linear forwards`
                          : "none",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-2xl leading-[21px] text-[#d8d9d8] md:text-3xl lg:leading-9">
                    Your Loyalty Wallet
                  </div>
                  <div className="flex flex-col items-start justify-start gap-4 self-stretch">
                    <Item
                      leftTitle="Marriott"
                      leftSubtitle={
                        <div className="flex items-start justify-center gap-[6.55px] rounded-[20.97px] border border-white/25 bg-gradient-to-r from-[#cb9872] to-[#5e4b3d] px-[7.86px] py-[2.62px] text-xs font-normal uppercase text-[#162232] lg:text-sm">
                          Gold Elite
                        </div>
                      }
                      rightTitle="186,250 pts"
                      rightSubtitle="Value: $1,584"
                      addDivider
                      delay={0}
                    />
                    <Item
                      leftTitle="united"
                      leftSubtitle={
                        <div className="flex items-start justify-center gap-[6.55px] rounded-[20.97px] border border-white/25 bg-gradient-to-r from-[#9cb8c9] to-[#567786] px-[7.86px] py-[2.62px] text-xs font-normal uppercase text-[#162232] lg:text-sm">
                          Premier Silver
                        </div>
                      }
                      rightTitle="109,600 pts"
                      rightSubtitle="Value: $1,424"
                      addDivider
                      delay={0.5}
                    />
                    <Item
                      leftTitle="American Express"
                      leftSubtitle={
                        <div className="flex items-start justify-center gap-[6.55px] rounded-[20.97px] border border-white/25 bg-gradient-to-r from-[#959595] via-[#d7d7d7] to-[#6e6d6b] px-[7.86px] py-[2.62px] text-xs font-normal uppercase text-[#162232] lg:text-sm">
                          Platinum Card
                        </div>
                      }
                      rightTitle="88,150 pts"
                      rightSubtitle="Value: $1,763"
                      addDivider
                      delay={1}
                    />
                    <Item
                      leftTitle="Total Est. Value"
                      rightTitle={
                        <div
                          className={cn(
                            "text-3xl font-light leading-[52.80px] text-primary-gold lg:text-5xl",
                            ppeikoFont.className
                          )}
                        >
                          $4,771
                        </div>
                      }
                      // rightSubtitle="Value: $655"
                      delay={1.5}
                    />
                  </div>
                  <motion.div
                    initial={{ filter: "blur(15px)", opacity: 0 }}
                    animate={{ filter: "blur(0)", opacity: 1 }}
                    transition={{
                      duration: REVEAL_ANIMATION_DURATION / 1000,
                      delay: 2 * (REVEAL_ANIMATION_DURATION / 1000),
                    }}
                    className="w-full"
                  >
                    <Button
                      className="flex h-14 w-full items-center justify-center self-stretch rounded-[6.89px] bg-primary-gold px-5 py-4 text-xs font-medium leading-[17.06px] text-[#050e19] lg:text-base"
                      onClick={onClick}
                    >
                      See Your Points
                      <ArrowRight className="h-4 w-4 lg:h-6 lg:w-6" />
                    </Button>
                  </motion.div>
                </>
              )}
            </div>
            <img
              className="absolute bottom-0 left-0 right-0 top-0 -z-10 h-full w-full object-cover"
              src="/assets/landing-page/loyalty-dashboard/map-background.png"
              alt="map-background"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Divider: React.FC = () => {
  return <div className="h-[0px] self-stretch border border-white/20"></div>;
};

type ItemProps = {
  leftTitle: ReactNode;
  leftSubtitle?: ReactNode;
  rightTitle: ReactNode;
  rightSubtitle?: ReactNode;
  addDivider?: boolean;
  delay?: number;
};

const Item: React.FC<ItemProps> = ({
  leftSubtitle,
  leftTitle,
  rightSubtitle,
  rightTitle,
  addDivider,
  delay = 0,
}) => {
  return (
    <motion.div
      className="flex h-full w-full flex-col gap-4"
      initial={{ filter: "blur(15px)", opacity: 0 }}
      animate={{ filter: "blur(0)", opacity: 1 }}
      transition={{
        duration: REVEAL_ANIMATION_DURATION / 1000,
        delay: delay * (REVEAL_ANIMATION_DURATION / 1000),
      }}
    >
      <div className="flex items-center justify-end gap-3 self-stretch rounded-lg px-[13.79px] lg:gap-[20.68px]">
        <div className="flex flex-col items-start justify-center gap-[5.17px]">
          <div className="font-light capitalize text-[#b1b1b1] lg:text-[28px]">
            {leftTitle}
          </div>
          {leftSubtitle}
        </div>
        <div className="flex shrink grow basis-0 flex-col items-end justify-start gap-1 lg:gap-[20.68px]">
          <div className="text-xl font-light leading-9 text-[#c7c8ca] lg:text-2xl lg:text-[32px]">
            {rightTitle}
          </div>
          {rightSubtitle && (
            <div className="text-xs font-light leading-[23.10px] text-[#c99a7a] lg:text-[21px]">
              {rightSubtitle}
            </div>
          )}
        </div>
      </div>
      {addDivider && <Divider />}
    </motion.div>
  );
};

export default LoyaltyDashboardCard;
